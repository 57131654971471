import { Injectable } from '@angular/core';
import { AuthStore } from '@core/state/auth.store';
import { UsersRolesStore } from '@ddb/state/users-roles.store';
import { RoleName } from '@ddb/enums/role-name';
import { filterSuccess } from '@core/utils/filter-success';
import { filterNullish } from '@core/utils/filter-nullish';
import { combineLatest, map, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PermissionsService {
  userCanManageProjects$ = this.authStore.activeAccount$.pipe(
    map(
      (user) =>
        user?.idTokenClaims?.roles?.includes('Projects.Manage') ?? false,
    ),
  );

  constructor(
    private readonly authStore: AuthStore,
    private readonly usersRolesStore: UsersRolesStore,
  ) {}

  public userHasRolesForResource(
    resrouceId: string,
    roleNames: RoleName[],
  ): Observable<boolean> {
    this.usersRolesStore.loadById(resrouceId);
    return combineLatest([
      this.authStore.user$.pipe(filterNullish()),
      this.usersRolesStore.selectById(resrouceId).pipe(filterSuccess()),
    ]).pipe(
      map(
        ([user, usersRoles]) =>
          roleNames.every((roleName) =>
            usersRoles.data
              .find(({ ms_id }) => ms_id === user.id)
              ?.roles.map(({ name }) => name)
              .includes(roleName),
          ) ?? false,
      ),
    );
  }
}
