import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpEntityStore } from '@core/state/http-entity.store';
import { UsersRolesService } from '@ddb/services/users-roles.service';
import { UserRolesModel } from '@ddb/models/user-roles.model';
import { tapResponse } from '@ngrx/component-store';
import { switchMap, expand, reduce, EMPTY } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UsersRolesStore extends HttpEntityStore<UserRolesModel[]> {
  constructor(
    private readonly usersRoles: UsersRolesService,
    private readonly snackBar: MatSnackBar,
  ) {
    super();
  }

  loadById = this.effect<string>((referanceId$) =>
    referanceId$.pipe(
      switchMap((referanceId) => {
        this.setLoading(referanceId);
        return this.usersRoles.get(referanceId).pipe(
          expand((response) =>
            response.paging.next && response.paging.cursors?.after
              ? this.usersRoles.get(referanceId, response.paging.cursors.after)
              : EMPTY,
          ),
          reduce(
            (acc, response) => acc.concat(response.users_roles),
            <UserRolesModel[]>[],
          ),
          tapResponse(
            (userRoles) => this.setSuccess([referanceId, userRoles]),
            (error: HttpErrorResponse) => {
              console.error(error);
              this.setError([referanceId, error]);
              this.snackBar.open(
                `Failed to load user roles for ${referanceId}`,
                'Dismiss',
              );
            },
          ),
        );
      }),
    ),
  );
}
